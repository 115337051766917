<template>
  <div style="margin: -15px -15px; background-color: #f0f2f5">
    <a-spin :spinning="spinning">
      <!-- tag查询框 -->
      <div class="datePicker">
        <div style="margin-right:60px">
            状态:
            <a-radio-group
              style="margin-left: 5px;margin-right:10px"
              v-model="searchForm.certStatus"
              @change="enterBtn"
            >
              <a-radio-button value="0"> 待处理 </a-radio-button>
              <a-radio-button value="1"> 已处理 </a-radio-button>
            </a-radio-group>
        </div>
        <div>
            姓名:
            <a-input
              v-model="searchForm.name"
              style="width: 80%"
              @pressEnter="enterBtn"
              placeholder="请输入"
            />
        </div>
        <a-button type="primary" style="margin-left: 30px" @click="enterBtn">查询</a-button>
      </div>
      <div style="padding: 20px; background-color: #fff">
        <a-table
          :columns="this.searchForm.certStatus == '0'?col1umns:columns"
          style="min-height: 500px; background-color: #fff"
          :data-source="HeplTable"
          :rowKey="(record) => record.id"
          :pagination="false"
        >
          <span slot="status" slot-scope="text, record">
            <div v-if="text === '已处理'">
              <admin_con
                style="color: #02ac27"
                class="icon"
                type="icon-yuandian1"
              />已处理
            </div>
            <div v-if="text === '未处理'">
              <admin_con
               style="color: #0e77d1"
               class="icon"
               type="icon-yuandian1"
             />待处理
            </div>
          </span>
          <span slot="operation" slot-scope="text, record">
            <a v-if="record.status == '未处理'" @click="amendBtnTable(record.id)">审核</a>
            <a v-if="record.status == '已处理'" @click="amendViewBtnTable(record.id)">查看</a>
          </span>
        </a-table>
        <!-- table框的分页 -->
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top:20px
          "
        >
          <span
            style="
              font-size: medium;
              color: #929292;
              font-size: 14px;
              bottom: 3%;
            "
          >
            共{{ pagination.total }}条记录 第 {{ pagination.pageNum }} /
            {{ Math.ceil(pagination.total / pagination.pageSize) }} 页
          </span>
          <a-pagination
            style="float: right"
            show-quick-jumper
            show-size-changer
            :page-size.sync="pagination.pageSize"
            :total="pagination.total"
            v-model="pagination.current"
            @change="pageChange"
            @showSizeChange="pageChange"
          />
        </div>
      </div>
      <!-- 专家审核框 -->
      <a-drawer
        title="审核/查看"
        placement="right"
        :closable="false"
        :visible="Visible"
        @close="addClose"
        width="500"
      >
        <div>
          <h3>专家信息</h3>
          <a-form-model
            ref="addRuleForm"
            :model="addForm"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :rules="this.searchForm.certStatus == '0'?rules:rules1"
          >
            <a-form-model-item label="姓名" prop="expertName">
              <a-input
                style="width: 300px"
                v-model="addForm.expertName"
                :disabled="true"
              />
            </a-form-model-item>
            <a-form-model-item label="医院">
              <a-input
                style="width: 300px"
                v-model="addForm.expertHospital"
                :disabled="true"
              />
            </a-form-model-item>
            <a-form-model-item label="照片">
              <!-- <a-input
                style="width: 300px"
                v-model="addForm.expertHospital"
                :disabled="true"
              /> -->
              <!-- expertHead -->
              <img style="width:100px;height:auto" :src="addForm.expertHead" alt="">
              <span v-if="addForm.expertHead == null">暂无</span>
            </a-form-model-item>
            <h3>用户信息</h3>
            <a-form-model-item label="身份" prop="memberType">
              <a-select
                show-search
                option-filter-prop="children"
                v-model="addForm.memberType"
                style="width: 300px"
                placeholder="请选择"
              >
              <!-- @change="selectNumberType" -->
                <a-select-option value="1001"> 医生 </a-select-option>
                <a-select-option value="1002"> 护士 </a-select-option>
                <a-select-option value="1003"> 医技 </a-select-option>
                <a-select-option value="1004"> 药师 </a-select-option>
                <a-select-option value="1005"> 学生 </a-select-option>
                <a-select-option value="1006"> 其他医务从业者</a-select-option>
                <a-select-option value="1007"> 非医务从业者 </a-select-option>
                <a-select-option value="0">身份不明人员</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="姓名" prop="expertName">
              <a-input
                style="width: 300px"
                v-model="addForm.memberName"
                :disabled="true"
              />
            </a-form-model-item>
            <a-form-model-item label="医院">
              <a-input
                style="width: 300px"
                v-model="addForm.memberHospital"
                :disabled="true"
              />
            </a-form-model-item>
            <a-form-model-item label="科室">
              <a-input
                style="width: 300px"
                v-model="addForm.memberOffices"
                :disabled="true"
              />
            </a-form-model-item>
            <a-form-model-item label="职称">
              <a-input
                style="width: 300px"
                v-model="addForm.memberTitle"
                :disabled="true"
              />
            </a-form-model-item>
            <a-form-model-item label="手机号">
              <a-input
                style="width: 300px"
                v-model="addForm.memberTel"
                :disabled="true"
              />
            </a-form-model-item>
            <a-form-model-item label="认证状态">

              <span v-if="addForm.certStatus === 'UNCERTIFIED' || !addForm.certStatus">未认证</span>
              <a v-if="addForm.certStatus === 'IN_REVIEW'" style="color: orange" @click="openNotificationBotton('topLeft')" >待审核，点击审核</a>
              <a v-if="addForm.certStatus === 'CERTIFIED'" @click="openNotification('topLeft')">已认证</a>

            </a-form-model-item>
          </a-form-model>
        </div>
        <!-- v-if="this.addForm.status == '未处理'" -->
        <div
           v-if="this.addForm.status == '未处理'"
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="deSubmit()">
            不是专家本人
          </a-button>
          <!-- <a-popconfirm title="确认认领吗？"  ok-text="是" cancel-text="否" @confirm="onSubmit()">
            <a slot="content">Close</a>
            <a-button type="primary"> 专家本人 </a-button>
          </a-popconfirm> -->
          <a-popover class="popover" v-model="popoverVisible"  trigger="click" style="position:relative;">
            <a slot="content" > <a-icon type="info-circle" style="color:red;margin-right:5px"/><span style="color:black">确认认领吗?</span>    <br></a>
            <a slot="content" ></a>
            <a slot="content" style="color:red;font-size:12px" > 认领后，专家信息将会替换用户信息 <br> </a>
            <a slot="content" style="color:red;font-size:12px" >  <br> </a>
            <a slot="content" style="color:red;font-size:12px" >  <br> </a>
            <a slot="content" style="position: absolute;right:20px;bottom:20px" >
              <a-button size="small" style="margin-right:10px" @click="popoverCalcel">取消</a-button>
              <a-button size="small" type="primary" @click="onSubmit"> 确认 </a-button>  </a>
            <a slot="content" > </a>
            <a-button type="primary"> 专家本人 </a-button>
          </a-popover>
        </div>
      </a-drawer>

      <a-drawer
          width="500"
          title="驳回原因"
          placement="right"
          :closable="false"
          :visible="rejectedShow"
          @close="handleCancel"
      >
        <a-textarea v-model="remark" :auto-size="{ minRows: 3, maxRows: 5 }"></a-textarea>
        <span v-if="remarkDetail" style="color: red;margin-top: 5px">请输入</span>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="handleCancel">
            取消
          </a-button>
          <a-button type="primary" @click="rejectedOk">
            确认
          </a-button>
        </div>

      </a-drawer>
    </a-spin>
  </div>
</template>

<script>
import { admin_icon } from "@/utils/myIcon_f";
  import {
    getExportHomePage
  } from "../../../service/document_api";
  import {
    getExpertRenLings,getexpertRenLingById,getexpertAgree,postexpertRefuse
  } from "../../../service/MembershipReviewManagement";
import {getAuditId, putAudit} from "@/service/memberImport_api";
  export default {
    components: {
      admin_con: admin_icon,
    },
    data() {
      return {
        writeBackImg:require('../../../assets/img_3.png'),
        remark:'',
        remarkDetail:false,
        rejectedShow:false,
        popoverVisible:false,
        expertId:"",
        labelCol: { span: 5 },
        wrapperCol: { span: 14 },
        addForm:{},
        Visible:false,
        spinning:false,
        //顶部搜索
        searchForm: {
          number: "",
          name: "",
          certStatus: "0",
        },
        timeVal: [],
        pagination: {
          //存储大table的分页
          total: 0,
          current: 1,
          pageSize: 10,
          pageNum: 1,
        },
        col1umns: [
          {
            title: "姓名",
            dataIndex: "memberName",
            key: "memberName",
            // align: "center",
            // width: "8%",
          },
          {
            title: "医院",
            dataIndex: "memberHospital",
            key: "memberHospital",
            // align: "center",
            // width: "15%",
          },
          {
            title: "认证专家姓名",
            dataIndex: "expertName",
            key: "expertName",
            // align: "center",
            // width: "28%",
          },
          {
            title: "认证专家医院",
            dataIndex: "expertHospital",
            key: "expertHospital",
            // align: "center",
            // width: "15%",
          },
          {
            title: "认证位置",
            dataIndex: "location",
            key: "location",
            // align: "center",
            // scopedSlots: { customRender: "cancelChannel" },
          },
          {
            title: "状态",
            // width: "13%",
            dataIndex: "status",
            key: "status",
            scopedSlots: { customRender: "status" },
          },
          {
            title: "提交时间",
            // width: "13%",
            dataIndex: "createdTime",
            key: "createdTime",
          },
          {
            title: "操作",
            dataIndex: "operation",
            fixed: "right",
            width: 100,
            scopedSlots: { customRender: "operation" },
          },
        ],
        columns: [
          {
            title: "姓名",
            dataIndex: "memberName",
            key: "memberName",
            // align: "center",
            // width: "8%",
          },
          {
            title: "医院",
            dataIndex: "memberHospital",
            key: "memberHospital",
            // align: "center",
            // width: "15%",
          },
          {
            title: "认证专家姓名",
            dataIndex: "expertName",
            key: "expertName",
            // align: "center",
            // width: "28%",
          },
          {
            title: "认证专家医院",
            dataIndex: "expertHospital",
            key: "expertHospital",
            // align: "center",
            // width: "15%",
          },
          {
            title: "认证位置",
            dataIndex: "location",
            key: "location",
            // align: "center",
            // scopedSlots: { customRender: "cancelChannel" },
          },
          {
            title: "状态",
            // width: "13%",
            dataIndex: "status",
            key: "status",
            scopedSlots: { customRender: "status" },
          },
          {
            title: "审核结果",
            // width: "13%",
            dataIndex: "result",
            key: "result",
          },
          {
            title: "提交时间",
            // width: "13%",
            dataIndex: "createdTime",
            key: "createdTime",
          },
          {
            title: "操作",
            dataIndex: "operation",
            fixed: "right",
            width: 100,
            scopedSlots: { customRender: "operation" },
          },
        ],
        HeplTable: [],
        rules: {
            memberType: [{ required: true, message: "请输入", trigger: "change" }],
        },
        rules1: {
            memberType: [{ required: false, message: "请输入", trigger: "change" }],
        },
      };
    },
    created() {
      this.getTable();
    },
    methods: {
      handleCancel(){
        this.remarkDetail = false
        this.rejectedShow = false
      },
      //驳回
      async rejectedOk() {
        if (this.remark) {
          let data = {
            remark: this.remark,
            status: "REJECT"
          }
          await this.rejectOrAgree(this.addForm.memberId, data)
          //重新调取用户接口并赋值
          this.remarkDetail = false
          this.rejectedShow = false

        } else {
          this.remarkDetail = true
        }
      },
      //驳回通过调用接口
      async rejectOrAgree(id, data) {
        const response = await putAudit(id, data)
        if(response){
          this.$message.success('操作成功~')
        }
      },
      async openNotification(placement) {
        const Info = await getAuditId(this.addForm.memberId)
        this.$notification.open({
          message: `认证材料`,
          description:
              <div style="height:600px;overflow-y:scroll">
                <div>
                  <img src={`${Info.data[0].employeeCardUrl ? Info.data[0].employeeCardUrl:this.writeBackImg}`} style='max-width:500px;height:auto;'/>
                </div>
                <div>
                  <img src={`${Info.data[0].practiceLicenseUrl ? Info.data[0].practiceLicenseUrl : this.writeBackImg}`} style='max-width:500px;height:auto;'/>
                </div>
                <div>
                  <img src={`${Info.data[0].nvqUrl ? Info.data[0].nvqUrl : this.writeBackImg}`} style='max-width:500px;height:auto;'/>
                </div>

              </div>,
          style:{
            "width":"600px",
            "min-height":"400px"
          },
          placement,
          duration: 0,
        });
      },
      async openNotificationBotton(placement) {
        const Info = await getAuditId(this.addForm.memberId)
        this.$notification.open({
          message: `认证材料`,
          description:
              <div>
                <div style="height:600px;overflow-y:scroll;">
                  <div>
                    <img src={`${Info.data[0].nvqUrl ? Info.data[0].nvqUrl : this.writeBackImg}`} style='max-width:500px;height:auto;'/>
                  </div>
                  <div>
                    <img src={`${Info.data[0].employeeCardUrl ? Info.data[0].employeeCardUrl:this.writeBackImg}`} style='max-width:500px;height:auto;'/>
                  </div>
                  <div>
                    <img src={`${Info.data[0].practiceLicenseUrl ? Info.data[0].practiceLicenseUrl : this.writeBackImg}`} style='max-width:500px;height:auto;'/>
                  </div>
                </div>
              </div>,
          btn: h => {
            return h('div', null, [
              h('a-button',
                  {
                    props: {
                      type: 'danger',
                      // type: 'primary',
                      // size: 'small',
                    },
                    on: {
                      click: () => {this.inReview()},
                    },
                  },
                  '驳回申请',),
              h('a-button',
                  {
                    props: {
                      type: 'primary',
                    },
                    attrs: {
                      style: 'margin: 0 0 0 10px;'
                    },
                    on: {
                      click: () => {this.agreedBtn()},
                    },
                  },
                  '审核通过',),
            ])
          },

          style:{
            "width":"600px",
            "min-height":"400px",
            "z-index":'999'
          },
          placement,
          duration: 0,
        });
      },
      //打开驳回对话框
      inReview(){
        this.rejectedShow = true
      },
      //通过
      agreedBtn(){
        let data = {
          status: "AGREED"
        }
        this.rejectOrAgree(this.addForm.memberId,data)
      },
        // 点击审核
      async amendBtnTable(id){
        this.Visible = true
        this.expertId = id
        const response = await getexpertRenLingById(id);
        if (response.code === 200) {
        //   console.log("单条",response.data)
          this.addForm = JSON.parse(JSON.stringify(response.data))
          //   默认展示医生
          if(this.addForm.memberType == null){
            this.addForm.memberType = "1001"
          }else if(typeof this.addForm.memberType == 'number'){
            this.addForm.memberType = this.addForm.memberType.toString()
          }
        } else {
          this.$message.warning(response.message);
        }
      },
      // 点击查看
      async amendViewBtnTable(id){
        this.Visible = true
        this.expertId = id
        const response = await getexpertRenLingById(id);
        if (response.code === 200) {
        //   console.log("单条",response.data)
          this.addForm = JSON.parse(JSON.stringify(response.data))
          if(this.addForm.memberType == null){
            this.addForm.memberType = undefined
          }else if(typeof this.addForm.memberType == 'number'){
            this.addForm.memberType = this.addForm.memberType.toString()
          }
        } else {
          this.$message.warning(response.message);
        }
      },
      addClose(){
        this.Visible = false
      },
      enterBtn() {
        this.getTable();
      },
      // 分页
      pageChange(page_no, page_size) {
        this.pagination.pageNum = page_no;
        this.pagination.pageSize = page_size;
        this.getTable();
      },
      // 获取列表
      async getTable() {
        this.spinning = true
        let data = {}
        data = {
            status:this.searchForm.certStatus,
            name:this.searchForm.name,
            page:this.pagination.pageNum,
            pageSize:this.pagination.pageSize
        }
        const response = await getExpertRenLings(data);
        if (response.code === 200) {
        //   console.log("浏览请求",response.data)
          this.HeplTable = response.data;
          this.pagination.total = response.count;
        } else {
          this.$message.warning(response.message);
        }
        this.spinning = false
      },
      // 气泡框取消
      popoverCalcel(){
        this.popoverVisible = false;
      },
      // 专家本人
      async onSubmit(){
        this.$refs.addRuleForm.validate((valid) => {
          if (valid) {
            // const response = getexpertAgree(this.expertId,this.addForm.memberType);
            // if (response.statuscode == 200) {
            //   this.$message.success("操作成功");
            //   this.popoverVisible = false;
            //   this.Visible = false
            //   this.getTable()
            // }else {  //状态码异常
            //   this.$message.success("操作成功");
            //   this.popoverVisible = false;
            //   this.Visible = false
            //   this.getTable()
            // }
            this.postExpert()
          }})
      },

      // 专家本人接口
      async postExpert(){
        const response = getexpertAgree(this.expertId,this.addForm.memberType);
          if (response.statuscode == 200) {
            this.$message.success("操作成功");
            this.popoverVisible = false;
            this.Visible = false
            this.getTable()
          }else {  //状态码异常
            this.$message.success("操作成功");
            this.popoverVisible = false;
            this.Visible = false
            this.getTable()
          }
      },

      // 不是专家本人
      async deSubmit(){
        const data = {
          result:"不是专家本人"
        }
        const response = await postexpertRefuse(this.expertId,data);
        if (response.code === 200) {
          this.$message.success("操作成功");
          this.Visible = false
          this.getTable()
        } else {
          this.$message.warning(response.message);
        }
      }
    },
  };
  </script>

  <style scoped>
  .datePicker {
    background-color: #fff;
    width: 100%;
    height: 64px;
    padding: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
  }
  ::v-deep .ant-popover-inner-content {
    padding: 12px 16px;
    padding-top:2px !important;
    color: rgba(0, 0, 0, 0.65);
}

  ::v-deep .ant-table-thead > tr > th {
    font-weight: bold;
  }
  .icon {
  font-size: 8px;
  margin-right: 5px;
}
::v-deep .ant-drawer-title {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
}
h3{
  font-weight:600
}
.popover ::v-deep .ant-popover-title {
    padding-left:31px  !important;
}

::v-deep .ant-popover-inner .ant-popover-title {
    min-width: 177px;
    min-height: 32px;
    margin: 0;
    padding: 5px 29px 4px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    /* border-bottom: 1px solid #e8e8e8; */
}


  </style>
