import { get, put, post, del, getDrugExel ,postBId } from "./base";

// 会员审核管理列表
export function getExpertRenLings(data) {
  return get("/v3/expert/renLing/expertRenLings", data);
}
// 获取单个会员记录
export function getexpertRenLingById(id) {
  return get(`/v3/expert/renLing/expertRenLing/${id}`);
}
// 专家本人
export function getexpertAgree(id,type) {
  return postBId(`/v3/expert/renLing/agree/${id}/${type}`);
}
// 不是专家本人
export function postexpertRefuse(id,data) {
    return postBId(`/v3/expert/renLing/refuse/${id}`,data);
  }